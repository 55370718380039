.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fdfdfd;
    border-radius: 10px;
    position: sticky;
    top: 0;
}

.question-container {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2rem);
    max-height: 700px;
}

.question-list {
    padding: 0 1rem;
    overflow-y: auto;
}

.question-item {
    position: relative;
    width: 100%;
    height: calc((29vw - 46px) / 4);
    cursor: pointer;
    border-radius: 5;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
}

.question-item:hover {
    transform: translateY(-5px);
}

.question-item:hover .edit-icon {
    color: #fff;
}

.question-item:hover .overlay {
    background-color: rgba(0, 0, 0, 0.2);
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    box-sizing: border-box;
}

.edit-icon {
    color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease;
}

.new-question-card {
    width: 100%;
    height: 100%;
    border: 2px dashed #aaa;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    flex-grow: 1;
}

.new-question-card:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.new-question-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 1;
    height: calc((29vw - 46px) / 4);
}

.plus-symbol {
    font-size: 48px;
    font-weight: bold;
    color: #888;
}

.question-pane-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

/* Selection tab pane container */
.selection-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2rem);
}

.selection-container .image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.selection-container .image-list .image-item {
    flex-basis: calc(100% - 1rem);
    box-sizing: border-box;
    padding: 0.5rem 0;
    margin: 0;
}

.image-list-container {
    max-height: 475px;
    overflow-y: auto;
    margin: 0.5rem 0;
    flex-grow: 1;
}

.input-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-field {
    width: 100%;
}

.input-field.error {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.auto-field {
    width: 100%;
}

.auto-field.error .ant-input {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.auto-field.error .ant-select-selector {
    border-color: #ff4d4f !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}

.selection-pane-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

/* Switch */
.switch-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
}

.switch {
    margin: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.custom-switch .ant-switch-checked {
    background-color: green;
}