.inputWithSuffix {
    width: 75px;
}

.flexContainer {
    display: flex;
    align-items: center;
}

.tag {
    border-radius: 15px;
}