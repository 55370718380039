.pdf-document {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page {
  margin: 10px 0;
}
