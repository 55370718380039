.rubricsCard {
  width: 100%;
}

.criterion {
  display: flex;
  margin-bottom: 16px;
  border-radius: 15px;
}

.columnsContainer {
  display: flex;
  width: 100%;
}

.descriptionColumn {
  width: 150px;
  padding: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 5px 0 0 5px;
}

.ratingsColumn {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.rating {
  flex: 1 1 100px;
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
  margin: 0 8px 8px 0;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.rating:last-child {
  margin-right: 0;
}

.pointsColumn {
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 0 5px 5px 0;
  margin-right: 10px;
}

.rating:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
  z-index: 1;
}

.selected {
  position: relative;
}

.selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100%);
  height: 4px;
  background-color: #00bcd4;
  border-radius: 0 0 5px 5px;
}

.totalPoints {
  margin-top: 16px;
  font-weight: bold;
}
