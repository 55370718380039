.pdf-part {
    position: absolute;
    outline: none;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s, background-color 0.3s;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.pdf-part.default {
    cursor: pointer;
}

.pdf-part.hovered {
    opacity: 1;
}

.pdf-part.selected {
    opacity: 1;
    background-color: rgba(56, 142, 248, 0.3);
}

.pdf-part.flash {
    opacity: 1 !important;
    background-color: rgba(56, 142, 248, 0.3) !important;
}

.plus-button {
    position: absolute;
    left: -20px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
}

.pdf-part.hovered .plus-button,
.plus-button.selected {
    opacity: 1;
}

.plus-icon,
.part-number {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

/* Edit mode */

.pdf-part.displayed {
    opacity: 1;
}

.pdf-part.displayed-hovered {
    opacity: 1;
    background-color: rgba(56, 142, 248, 0.3) !important;
}

.pdf-part__hover-buttons,
.pdf-part__resize-buttons,
.pdf-part__split-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 20px;
}

.pdf-part__hover-buttons {
    opacity: 0;
    transition: opacity 0.3s;
}

.pdf-part:hover .pdf-part__hover-buttons {
    opacity: 1;
}

.pdf-part__container {
    position: relative;
    width: 100%;
    height: 90%;
    top: 5%;
}

.pdf-part__split-container {
    position: absolute;
    width: 100%;
    display: flex;
    gap: 10px;
    z-index: 1;
}

.pdf-part__split-line {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    background: red;
    z-index: 1;
    cursor: row-resize;
}

.pdf-part__resize-buttons,
.pdf-part__split-buttons {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}

.drag-handle:active {
    cursor: row-resize;
}

.drag-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
    z-index: 1;
}

.drag-icon:active {
    cursor: grabbing;
}