.course-option-selector {
    text-align: center;
}

.course-option-card {
    transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
}

.course-option-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.course-option-card:hover~.course-option-card {
    opacity: 0.5;
}