.coursegrid-row .ant-card-body {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.course-name {
    padding: 8px 0 16px 0;
    text-align: center;
    font-weight: bold;
}

.coursegrid-list .ant-list-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
}

.coursegrid-list .ant-list-item:last-child {
    border-bottom: none;
}