.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.pdf-page-container {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column; /* Change this based on the orientation of pages */
    align-items: flex-start;
}

.page-and-button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
}

.pdf-page-wrapper {
    position: relative;
    display: inline-block;
}

.pdf-page-wrapper canvas {
    display: block;
}

.pdf-page {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.add-part-button {
    position: absolute;
    margin-right: 10px;
    left: -40px;
    top: 50%;
    z-index: 1;
}

.new-part-overlay {
    position: absolute;
    border: 2px dashed #000;
    background-color: rgba(0, 0, 255, 0.2);
}