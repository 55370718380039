.help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1890ff;
    border-color: #1890ff;
}

.help-button:hover,
.help-button:focus {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}