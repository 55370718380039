.inputWithSuffix {
    width: 75px;
}

.grading {
    margin-right: 20px;
}

.flexContainer {
    display: flex;
    align-items: center;
}

.tag {
    border-radius: 15px;
}

::-webkit-scrollbar {
    width: 5px;
  }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 35px;
}

.submissionText {
  font-size: 16px;
}