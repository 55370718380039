.wrapper-class {
    margin-bottom: 24px;
}

.editor-class {
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    padding: 4px;
    min-height: 200px;
    background-color: #ffffff;
}

.toolbar-class {
    border: 1px solid #ccc;
    border-radius: 7px;
}